import { TravelType, StopsType, ConfigFile } from './types'
import { AxiosRequestConfig } from 'axios'
import s from './config.json'

const settings: ConfigFile = s

const env = process.env.NODE_ENV || 'development'
class Config {
  public static instance: Config

  private _httpConfig: AxiosRequestConfig = settings[env].httpConfigs
  private _apiUrl = process.env.GATSBY_URL_API || ''
  private _authUrl = process.env.GATSBY_URL_AUTH || ''
  private _conektaPublicKey = process.env.GATSBY_CONEKTA_PUBLIC_KEY || ''
  private _imgPath = `${process.env.GATSBY_URL_IMG}/api/v1.0.0/image`
  private _recaptcha = process.env.GATSBY_RECAPTCHAKEY || ''
  private _maps = process.env.GATSBY_MAPSKEY || ''
  private _domainName = process.env.GATSBY_DOMAIN_NAME_MEDIA || ''
  private _googleAnalyticsId = process.env.GATSBY_GOOGLE_ANALYTICS_ID || ''
  private _rfcParcel = process.env.GATSBY_RFC_PARCEL || ''
  private _rfcBoardingPass = process.env.GATSBY_RFC_BOARDINGPASS || ''
  private _rfcNameParcel = process.env.GATSBY_RFC_NAME_PARCEL || ''
  private _rfcNameBoardingPass = process.env.GATSBY_RFC_NAME_BOARDINGPASS || ''
  private _facebookPixelId = process.env.GATSBY_FACEBOOK_PIXEL_ID || ''
  private _mercadoPagoPublicKey = process.env.GATSBY_MERCADOPAGO_PUBLIC_KEY || ''

  constructor() {
    if (Config.instance) {
      return Config.instance
    }
    Config.instance = this
  }

  get httpConfig(): AxiosRequestConfig {
    return this._httpConfig
  }

  get apiUrl(): string {
    return this._apiUrl
  }

  get authUrl(): string {
    return this._authUrl
  }

  get imgPath(): string {
    return this._imgPath
  }

  get recaptcha(): string {
    return this._recaptcha
  }

  get maps(): string {
    return this._maps
  }

  get cfdi(): string {
    return this._domainName
  }

  get conektaPublicKey(): string {
    return this._conektaPublicKey
  }

  get travelType(): TravelType {
    return {
      1: 'Abierto',
      2: 'Sencillo',
      3: 'Redondo'
    }
  }

  get stopsType(): StopsType {
    return {
      0: 'Directo',
      1: 'Escala',
      2: 'Ordinario'
    }
  }

  get xAccessToken(): string {
    return 'otentikasi'
  }

  get xExpiresToken(): string {
    return 'otentikasi_exp'
  }

  get origin(): 2 {
    return 2
  }

  get googleAnalyticsId(): string {
    return this._googleAnalyticsId
  }

  get rfcParcel(): string {
    return this._rfcParcel
  }

  get rfcBoardingPass(): string {
    return this._rfcBoardingPass
  }

  get rfcNameParcel(): string {
    return this._rfcNameParcel
  }

  get rfcNameBoardingPass(): string {
    return this._rfcNameBoardingPass
  }

  get facebookPixelId(): string {
    return this._facebookPixelId
  }

  get mercadoPagoPublilcKey(): string {
    return this._mercadoPagoPublicKey
  }
}

const instance = new Config()

Object.freeze(instance)

export default instance
