import HttpClient from '../../httpClient/HttpClient'
import Config from '../../config/Config'
import axios, { AxiosRequestConfig, AxiosPromise, AxiosError, AxiosResponse } from 'axios'
import { ApiErrors, ApiResponse, ApiResponseStatus } from './types'

function getUrl(path: string, url?: string) {
  return `${url || Config.apiUrl}/${path}`
}

const timeOutError = 'Error al obtener datos del servidor. Intente de nuevo mas tarde.'

const request = <T>(axiosPromise: AxiosPromise<ApiResponse<T>>): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    axiosPromise
      .then(response => {
        const result = response.data
        if (result.status === ApiResponseStatus.OK) {
          resolve(<T>result.data)
        } else {
          throw { response }
        }
      })
      .catch((error: AxiosError & AxiosResponse<T>) => {
        if (error.response) {
          const status = error.response.status
          const err: ApiResponse = error.response.data

          if (status === 500) {
            if (err.devMessage && err.devMessage.includes('Timed out')) {
              console.error()
              reject({
                status: ApiErrors.HANDLED_ERROR,
                message: timeOutError
              })
            }
          } else {
            reject({
              status,
              error: err
            })
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error('The request was made but no response was received', error.request)
          reject({
            err: error
          })
        } else if (axios.isCancel(error)) {
          reject({
            status: ApiErrors.CANCELED_REQUEST,
            message: error.message
          })
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error on setting up the request', error.message)
          reject({
            status: ApiErrors.HANDLED_ERROR,
            message: 'Ocurrió un error inesperado, comuníquese con el equipo de soporte.',
            err: error
          })
        }
      })
  })
}

class ApiService {
  public static instance: ApiService

  constructor() {
    if (ApiService.instance) {
      return ApiService.instance
    }
    ApiService.instance = this
  }

  get<R>(path: string, options?: AxiosRequestConfig): Promise<R> {
    return request<R>(HttpClient.get(getUrl(path, options && options.url), options))
  }

  post<T, R>(path: string, data: R, options?: AxiosRequestConfig): Promise<T> {
    return request<T>(HttpClient.post(getUrl(path, options && options.url), data, options))
  }

  put<T, R>(path: string, data: R, options?: AxiosRequestConfig): Promise<T> {
    return request<T>(HttpClient.put(getUrl(path, options && options.url), data, options))
  }

  delete<R>(path: string, options?: AxiosRequestConfig): Promise<R> {
    return request<R>(HttpClient.delete(getUrl(path, options && options.url), options))
  }

  getCancelToken() {
    return axios.CancelToken.source()
  }
}

const instance = new ApiService()

Object.freeze(instance)

export default instance
